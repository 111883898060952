import { inject }                      from 'aurelia-framework';
import { Router }                      from 'aurelia-router';
import { UnauthenticatedRouterConfig } from 'resources/configs/unauthenticated-router-config';

@inject(Router, UnauthenticatedRouterConfig)
export class AppOffline {

    /**
     * Constructor
     *
     * @param router
     * @param routerConfig
     */
    constructor(router, routerConfig) {
        this.router       = router;
        this.routerConfig = routerConfig;
    }

    /**
     * Handles activate event
     */
    activate() {
        this.routerConfig.configure();
    }

}
