import { inject }                   from 'aurelia-framework';
import { Router }                   from 'aurelia-router';
import { AuthService, FetchConfig } from 'aurelia-authentication';
import { CustomHttpClient }         from 'resources/services/custom-http-client';
import { RouterConfig }             from 'resources/configs/router-config';
import { AuthenticatedUser }        from 'resources/services/authenticated-user';
import { LaravelEchoService }       from 'resources/services/laravel-echo-service';

@inject(Router, CustomHttpClient, AuthService, FetchConfig, RouterConfig, AuthenticatedUser, LaravelEchoService)
export class App {

    /**
     * Constructor
     *
     * @param router
     * @param customHttpClient
     * @param authService
     * @param fetchConfig
     * @param routerConfig
     * @param authenticatedUser
     * @param laravelEchoService
     */
    constructor(router, customHttpClient, authService, fetchConfig, routerConfig, authenticatedUser, laravelEchoService) {
        this.router             = router;
        this.customHttpClient   = customHttpClient;
        this.authService        = authService;
        this.fetchConfig        = fetchConfig;
        this.routerConfig       = routerConfig;
        this.authenticatedUser  = authenticatedUser;
        this.laravelEchoService = laravelEchoService;
    }

    /**
     * Handles activate event
     */
    activate() {
        this.fetchConfig.configure(this.customHttpClient);
        this.routerConfig.configure();

        return this.authenticatedUser.fetchAuthenticatedUser().then(() => this.laravelEchoService.instantiate());
    }

    /**
     * Runs after view is attached
     */
    attached() {
        this.defineHtmlBodyClassname();
        this.setupAjaxAuthorizationHeaders();
        this.generateJqueryCustomCssClassChangedEvent();
    }

    /**
     * Defines html body classname
     */
    defineHtmlBodyClassname() {
        document.body.className = 'navbar-top';
    }

    /**
     * Setups ajax authorization headers
     */
    setupAjaxAuthorizationHeaders() {
        $.ajaxSetup({
            headers: {
                'Authorization': `Bearer ${this.authService.getAccessToken()}`,
            },
        });
    }

    /**
     * Generates a custom `cssClassChanged` for jquery
     */
    generateJqueryCustomCssClassChangedEvent() {
        let originalAddClassMethod = jQuery.fn.addClass;

        // overrides the original `addClass` jQuery function
        jQuery.fn.addClass = function () {
            let result = originalAddClassMethod.apply(this, arguments);

            // trigger a custom event
            jQuery(this).trigger('cssClassChanged');

            return result;
        };
    }

}
